<div class="test" *ngIf="active" [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}" [@flyInOut]>
  <div class="slider-text">
    <h1 class="app-name">QXPlayer</h1>
<!--    <p class="article">{{text}}</p>-->
    <p class="article"> QXPlayer is an audiophile player that allows you enjoying high-quality music of various formats on your Mac. You will have full control over any of your audio files while utilizing the folder system that is in Finder. </p>
    <p class="article">
      Among the basic functions included in the initial release of QXPlayer V1.01 are:
        <span>
          Ability to listen to files in a variety of formats without any quality loss: FLAC, WAVE,  Apple Lossless, AAC, MP3, Ogg Vorbis, Musepack, WavPack, Monkey's Audio, Speex, True Audio, MOD, AIFF, and DSF are supported;
        </span>
    </p>
    <p class="article">
      Opportunity to choose between the external and internal storage devices;
    </p>
    <p class="article">
      Support of files and folders view;
    </p>
    <p class="article">
      CUE support, playlists, remote control via smartphone, equalizer, metadata editor are coming in the upcoming versions.
    </p>
    <p class="article">
      If you need a simple HQ player with the user-friendly interface, solid set of functions and tons of supported formats, QXPlayer is just the right option for you to try at a reasonable price.
    </p>
    <p class="article update">
      ONE-TIME PURCHASE - FREE UPDATES
    </p>
    <a href="https://apps.apple.com/us/app/dsplayer/id1481703720?ls=1&mt=12" target="_blank" class="link"><img src="../../../assets/logo.svg" alt="link"></a>
  </div>
</div>
