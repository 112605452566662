import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-crossfade-item',
  templateUrl: './crossfade-item.component.html',
  styleUrls: ['./crossfade-item.component.css'],
  animations: [
    trigger('flyInOut', [
      // state('*', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({
          opacity: 0,
          // transform: 'translateX(100%)'
        }),
        animate('3s ease-in-out')
      ]),
      transition('* => void', [
        animate('3s ease-in-out', style({
          opacity: 0,
          // transform: 'translateX(-100%)'
        }))
      ])
    ])
  ]
})
export class CrossfadeItemComponent implements OnInit {


  @HostBinding('@flyInOut')

  active = false;
  @Input() imageUrl: string;
  @Input() text: string;

  constructor() {
  }

  ngOnInit() {
  }

}
