import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
