<app-crossfade-images>
  <app-crossfade-item imageUrl="../../../assets/pic1-min.jpg" text = 'QXPlayer is an audiophile player that allows you enjoying high-quality music of various formats on your Mac. You will have full control over any of your audio files while utilizing the folder system that is in Finder. \n sdfdsf'>
  </app-crossfade-item>
  <app-crossfade-item imageUrl="../../../assets/pic2-min.jpg" text = 'QXPlayer is an audiophile player that allows you enjoying high-quality music of various formats on your Mac devices. You will have full control over any of your audio files while utilizing the folder system that is included in the set of functions of the software.
Among the basic functions included in the initial release of QXPlayer V1.01 are:
Ability to listen to files in a variety of formats without any quality loss: FLAC, WAVE,  Apple Lossless, AAC, MP3, Ogg Vorbis, Musepack, WavPack, Speex, True Audio, MOD, AIFF, and DSF are supported;
                      Opportunity to choose between the external and internal storage devices;
                      Support of files and folders view;
                      CUE support, playlists, smartphone control path, equalizer, metadata editor are coming in the upcoming versions.
                      If you need a simple HQ player with the user-friendly interface, solid set of functions and tons of supported formats, QXPlayer is just the right option for you to try at a reasonable price.'>
  </app-crossfade-item>
  <app-crossfade-item imageUrl="../../../assets/pic3-min.jpg" text = 'QXPlayer is an audiophile player that allows you enjoying high-quality music of various formats on your Mac devices. You will have full control over any of your audio files while utilizing the folder system that is included in the set of functions of the software.
Among the basic functions included in the initial release of QXPlayer V1.01 are:
Ability to listen to files in a variety of formats without any quality loss: FLAC, WAVE,  Apple Lossless, AAC, MP3, Ogg Vorbis, Musepack, WavPack, Speex, True Audio, MOD, AIFF, and DSF are supported;
                      Opportunity to choose between the external and internal storage devices;
                      Support of files and folders view;
                      CUE support, playlists, smartphone control path, equalizer, metadata editor are coming in the upcoming versions.
                      If you need a simple HQ player with the user-friendly interface, solid set of functions and tons of supported formats, QXPlayer is just the right option for you to try at a reasonable price.'>
  </app-crossfade-item>
</app-crossfade-images>
