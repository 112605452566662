import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private meta: Meta,
  ) {
    this.meta.addTags([
      {name: 'keywords', content: 'ios, player, audiophile, music, Mac, FLAC, WAVE,' +
          ' Apple Lossless, AAC, MP3, Ogg Vorbis, Musepack, WavPack, Monkeys Audio, Speex, True Audio, MOD, AIFF,'}
    ]);
  }
}
