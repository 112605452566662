import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { CrossfadeImagesComponent } from './crossfade-images/crossfade-images.component';
import { CrossfadeItemComponent } from './crossfade-images/crossfade-item/crossfade-item.component';
import { MainPageComponent } from './main-page/main-page.component';
import {AppRoutingModule} from './app-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SupportPageComponent } from './support-page/support-page.component';

@NgModule({
  declarations: [
    AppComponent,
    CrossfadeImagesComponent,
    CrossfadeItemComponent,
    MainPageComponent,
    MainLayoutComponent,
    SupportPageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MatCarouselModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
