import {AfterContentInit, Component, ContentChildren, OnInit} from '@angular/core';
import {CrossfadeItemComponent} from './crossfade-item/crossfade-item.component';

@Component({
  selector: 'app-crossfade-images',
  templateUrl: './crossfade-images.component.html',
  styleUrls: ['./crossfade-images.component.css']
})
export class CrossfadeImagesComponent implements AfterContentInit {

  @ContentChildren(CrossfadeItemComponent) items;

  constructor() {
  }

  ngAfterContentInit() {
    let active = 0;
    const items = this.items.toArray();
    const actives = this.items.filter(t => {
      return t.active;
    });

    if (actives.length > 1) {
    } else if (!actives.length && items.length) {
      items[active].active = true;
    }

    setInterval(() => {
      if (active === items.length - 1) {
        active = 0;
      } else {
        active++;
      }

      items[active].active = true;

      for (let i = 0; i < items.length; i++) {
        items[i].active = i === active;
      }

    }, 10000);
  }

}
