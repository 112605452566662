  <mat-card flex-gt-sm>
    <mat-card-title>QXPlayer support</mat-card-title>
    <mat-divider></mat-divider>
    <mat-list>

<!--      <mat-list-item>-->
<!--        <mat-icon matListAvatar>mail</mat-icon>-->
<!--        <h4 matLine>mdssolution@gmail.com</h4>-->
<!--        <p matLine>I am always open and happy to see any offers on cooperation and wishes on the development and adding the new functions to QXPlayer.</p>-->
<!--        <p matLine> You can contact me any moment via email:  </p>-->
<!--      </mat-list-item>-->
      <div class="support-text">
        <p>I am always open and happy to see any offers on cooperation and wishes on the development and adding the new
          functions to QXPlayer.
        You can contact me any moment via email: <a class="link" href="mailto:serge@mdssolution.com">serge@mdssolution.com</a></p>
      </div>

    </mat-list>
  </mat-card>
