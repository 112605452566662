import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {MainPageComponent} from './main-page/main-page.component';
import {SupportPageComponent} from './support-page/support-page.component';

const routes: Routes = [
  {path: '', component: MainPageComponent},
  {path: 'support', component: SupportPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],

})
export class AppRoutingModule {
}
